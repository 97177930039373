
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRouter } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import Filters from "./crafted/widgets/Filters.vue";
import FilterTags from "./crafted/widgets/FilterTags.vue";

export default defineComponent({
    name: "builder",
    components: { Filters, FilterTags },
    data() {
        return {
            tagsKey: 0,
            activeFilters: {},
            filtersPayload: {},
            filtersToShow: {
                age: false,
                bailliage: true,
                commander: false,
                country: true,
                dues: false,
                function: true,
                gender: true,
                grade: true,
                medal: false,
                member_type: false,
                other_association: true,
                period: true,
                postal_code: true,
                state: true,
                title: true,
                activity_sector: true,
                plaque: false,
                credit_card: false,
                institution_type: false,
                food_style: false,
                stars: false,
                price_category_promotion: false,
                price_category_due: false,
                type_due: false,
                relevant_due_year: false,
                type_chapitre: true,
                amount_accr: false,
                type_accr: false,
                biller_period: false,
                biller_actual_balance: false,
                biller_initial_balance: false,
            },
            loading: true,
            reservationSearch: "",
            reservations: [
                {
                    id: 0,
                    code: "ATVC4",
                    title: "2022 Concours International des Jeunes Chefs Rôtisseurs & Grand Chapitre Mexico",
                    member: "Alan Wayne John ORREAL-LIANG",
                    payment: "Bank transfer",
                    payment_status: 1,
                    date: "23 Jul 2022 07:36",
                },
                {
                    id: 1,
                    code: "UCIM9",
                    title: "2022 Concours International des Jeunes Chefs Rôtisseurs & Grand Chapitre Mexico",
                    member: "Sindre Fagermo HJELMSETH",
                    payment: "Bank transfer",
                    payment_status: 0,
                    date: "31 Jul 2022 07:22",
                },
            ] as any,
            currentPage: 1,
            reservationsCount: 0,
            totalPages: 0,
            selectedReservations: [] as any,
            actualFilter: "",
            actualCol: "",
            reservationsTh: [
                { label: "Code", class: "min-w-60px", colName: "code" },
                { label: "Reservation", class: "min-w-60px", colName: "reservation" },
                { label: "Member", class: "min-w-60px", colName: "member" },
                { label: "Payment method", class: "min-w-60px", colName: "payment" },
                { label: "Date", class: "min-w-60px", colName: "date" },
            ],
            perm_pos: "",
        };
    },
    setup() {
        const router = useRouter();
        const resultsPerPage = 10;

        onMounted(() => {
            setCurrentPageBreadcrumbs("Reservations list", []);
        });

        return { router, resultsPerPage };
    },
    mounted() {
        this.getReservations();
    },
    methods: {
        getReservations() {
            this.loading = true;
            setTimeout(() => {
                this.loading = false;
            }, 500);
        },
        editReservation(id: any) {
            this.router.push({ name: "reservation-details", params: { reservation_id: id } });
        },
        sortColumn(column: string, id: number) {
            var arrows = document.getElementById("chevrons" + id);

            // Hide all arrows
            Array.from(document.getElementsByClassName("chevrons-container") as any).map((filter: any) => {
                filter.style.display = "none";
            });

            // Display the right arrows
            if (arrows) arrows.style.display = "flex";

            // If we click for the first time on a column OR if we change the column
            if (this.actualCol === "" || this.actualCol !== column) {
                this.actualCol = column;
                this.actualFilter = "asc";
                this.handleChevron("show", "up");
                this.handleChevron("hide", "down");

                // Else if we click on the same column
            } else if (this.actualCol === column) {
                if (this.actualFilter === "asc") {
                    this.handleChevron("hide", "up");
                    this.handleChevron("show", "down");
                    this.actualFilter = "desc";
                } else if (this.actualFilter === "desc") {
                    this.handleChevron("show", "up");
                    this.handleChevron("hide", "down");
                    this.actualFilter = "asc";
                }
            }
            this.getReservations();
        },
        handleChevron(action: string, direction: string) {
            var chevrons: any;

            if (direction === "up") chevrons = Array.from(document.getElementsByClassName("fa-chevron-up") as any);
            else if (direction === "down") chevrons = Array.from(document.getElementsByClassName("fa-chevron-down") as any);

            if (action === "show")
                chevrons.map((chevron: any) => {
                    chevron.classList.add("active-chevron");
                });
            else if (action === "hide")
                chevrons.map((chevron: any) => {
                    chevron.classList.remove("active-chevron");
                });
        },
        handlePagination(pageNumber: any) {
            this.selectedReservations = [];
            var topCheckbox = document.getElementById("topCheckbox") as any;
            topCheckbox.checked = false;

            this.currentPage = pageNumber;
            this.getReservations();
        },
        checkAll() {
            var topCheckbox = document.getElementById("topCheckbox") as any;
            var checkboxes = Array.from(document.getElementsByClassName("custom-checkbox-events") as any);

            this.selectedReservations = [];

            if (topCheckbox.checked) {
                checkboxes.map((checkbox: any) => {
                    checkbox.checked = true;
                });

                this.reservations.map((reservation: any) => {
                    this.selectedReservations.push(reservation.id);
                });
            } else {
                checkboxes.map((checkbox: any) => {
                    checkbox.checked = false;
                });
                this.selectedReservations = [];
            }
        },
        handleActionSelect(reservation: any) {
            var focusedCheckbox = document.getElementById("checkbox" + reservation.id) as any;

            if (focusedCheckbox?.checked) {
                this.selectedReservations.push(reservation.id);
            } else {
                this.selectedReservations.map((localReservation: any) => {
                    if (reservation.id === localReservation.id) {
                        this.selectedReservations.splice(this.selectedReservations.indexOf(localReservation), 1);
                    }
                });
            }
        },
        formatNumber(num) {
            return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
        },
    },
});
